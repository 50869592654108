import { Box, Drawer, Modal } from '@mui/material';
import Image from 'next/image';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import { CloseButton, Content, HeroImageContainer } from './CartSummaryStyle';

interface TnCPopupProps {
  open: boolean;
  handlePopupState: () => void;
}

const TnCPopup: React.FC<TnCPopupProps> = (props) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const styles = useMemo(() => {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.background.bg1,
      borderRadius: 4,
      outline: 0,
      boxShadow: 24,
      // height: '600px',
      width: '664px',
      maxWidth: '664px',
      maxHeight: '80vh',
      overflow: 'auto',
      //   p: '24px',
    };
  }, [theme]);

  return width && width > getNumFromResolutionConstant(WIDTH.tabletMax) ? (
    <Modal open={props.open} onClose={props.handlePopupState}>
      <Box sx={styles}>
        <HeroImageContainer>
          <Image
            src="https://static.driffle.com/media-gallery/production/19ed6441-aeba-4883-b61c-357814b9c964_Black-friday-tnc.webp"
            layout="fill"
            priority
          />
        </HeroImageContainer>
        <Content>
          <p>Terms and conditions:</p>
          <ol>
            <li>1. Offer is valid for purchases before 25th Nov 08:00 UTC.</li>
            <li>
              2. Users are required to meet the €30 threshold in one
              transaction; i.e., if you have two transactions of €16 and €14
              each, you will not be eligible for this offer.
            </li>
            <li>
              3. Coupon codes will only be valid if the user has redeemed the
              key codes for their purchases.
            </li>
            <li>
              4. Coupon code is only redeemable over the Black Friday weekend.
              <br />
              <span>
                &rarr; Coupon code will be valid from 23rd Nov 10:00 UTC.
              </span>
              <br />
              <span>
                &rarr; Coupon code will be valid till 27th Nov 08:00 UTC.
              </span>
            </li>
            <li>5. The coupon code is only applicable once per user.</li>
            <li>
              6. The provided 20% off coupon code will have an upper limit of
              €10.
            </li>
          </ol>
        </Content>
        <Button
          style={{ margin: '0 auto 16px auto' }}
          width="211px"
          onClick={() => props.handlePopupState()}
        >
          Okay
        </Button>
        <CloseButton onClick={() => props.handlePopupState()}>
          <MaskIcon
            url="/icons/close-24.svg"
            width="24px"
            height="24px"
            color={'#fff'}
            selected
            margin="0"
          />
        </CloseButton>
      </Box>
    </Modal>
  ) : (
    <Drawer
      open={props.open}
      onClose={props.handlePopupState}
      anchor="bottom"
      style={{ zIndex: 60, position: 'relative' }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          maxHeight: '85vh',
        },
      }}
      PaperProps={{
        square: false,
        style: {
          borderRadius: '8px 8px 0 0 ',
          background: theme.palette.background.bg1,
        },
      }}
    >
      <Box>
        <HeroImageContainer>
          <Image
            src="https://static.driffle.com/media-gallery/production/f64b3dbf-f302-4cca-ad7b-1d0129c7193b_Black-Friday-Upsell-banner-desktop.webp"
            layout="fill"
            priority
          />
        </HeroImageContainer>
        <Content>
          <p>Terms and conditions:</p>
          <ol>
            <li>
              1. The Coupon code will be sent to users who have completed
              shopping before 00:00 PST on 24th November.
            </li>
            <li>
              2. Coupon codes will only be sent to users who have redeemed the
              key codes for their purchases. If a user makes a €30 purchase but
              does not redeem the key, she will not be eligible for the offer.
            </li>
            <li>
              3. The Coupon code will be sent to users who fulfill the above
              criteria on your Driffle email address.
            </li>
            <li>
              4. Users are required to meet the €30 threshold in one
              transaction; i.e., if you have two transactions of €16 and €14
              each, you will not be eligible for this offer.
            </li>
            <li>
              5. The 20% coupon code will have an upper limit of €20 and will be
              applicable for 7 days.
            </li>
            <li>
              6. The coupon code is only applicable once per eligible user for
              one transaction.
            </li>
          </ol>
        </Content>
        <Button
          style={{ margin: '0 auto 16px auto' }}
          width="211px"
          onClick={() => props.handlePopupState()}
        >
          Okay
        </Button>
        <CloseButton onClick={() => props.handlePopupState()}>
          <MaskIcon
            url="/icons/close-24.svg"
            width="24px"
            height="24px"
            color={'#fff'}
            selected
            margin="0"
          />
        </CloseButton>
      </Box>
    </Drawer>
  );
};

export default TnCPopup;
