import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const CLIContainer = styled.div`
  width: 100%;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  background-color: ${(props) => props.theme.palette.background.bg1};
  box-sizing: border-box;
  margin-bottom: 16px;
  border: 1px solid ${(props) => props.theme.palette.border.b1};
`;

export const CLIStoreName = styled.div`
  padding: 12px 16px;
  > p {
    color: #808080;
    font-size: 14px;
    line-height: 18px;
    font-family: Onest-Medium;
    > a > span {
      cursor: pointer;
      font-family: Onest-Semibold;
      color: ${(props) => props.theme.palette.text.t2};
    }
  }
  border-bottom: 1px solid
    ${(props) => (props.theme.mode === 'dark' ? '#2b2b2b' : '#e0e0e0')};
  @media (max-width: ${WIDTH.mobileMax}) {
    > p {
      font-size: 12px;
      line-height: 16px;
      > a > span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
`;
export const CLIProductData = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
export const CLIImageContainer = styled.div`
  height: 180px;
  width: 128px;
  cursor: pointer;
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 150px;
    width: 110px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 130px;
    width: 92px;
  }
`;
export const CLIProductImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
`;

export const CLIProductDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
`;

export const CLIGameName = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-family: Onest-Medium;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) => props.theme.palette.text.t1};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  // max-width: 350px;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 12px/16px Onest-SemiBold;
  }
`;

export const GameNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
`;

export const CLIProductTagContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const KeyTypeTag = styled.div`
  padding: 4px 8px;
  border-radius: 6px;
  max-height: 26px;
  width: max-content;
  background-color: ${(props) => props.theme.palette.background.bg3};
  > p {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: Onest-SemiBold;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const RegionTag = styled.div`
  font: 0.875rem/1.125rem Onest-Bold;
  color: ${(props) => (props.theme.mode === 'dark' ? '#48bdff' : '#359dff')};
  text-transform: uppercase;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 12px/16px Onest-Bold;
  }
`;

export const CLIPriceContainer = styled.div<{ isWarning?: boolean }>`
  border-top: 1px solid
    ${(props) => (props.theme.mode === 'dark' ? '#2b2b2b' : '#e0e0e0')};
  display: flex;
  width: 100%;
  align-items: end;
  padding-top: ${(props) => (!!props.isWarning ? '4px' : '8px')};
  justify-content: space-between;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 12px;
    padding-top: ${(props) => (!!props.isWarning ? '2px' : '6px')};
  }
`;

export const DabbaQty = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 8px;
  }
`;

export const QuantityContainer = styled.div`
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 7px;
  flex: 1;
  max-height: 30px;
  max-width: 88px;
  width: 100%;
  gap: 8px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 10px 0 0;
  }
`;

export const MinusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  background: ${(props) => props.theme.palette.background.bg3};
  cursor: ${(props: PlusMinusInteface) =>
    props.quantity > 0 ? 'pointer' : 'not-allowed'};

  @media (max-width: ${WIDTH.mobileMax}) {
  }
`;

export const QuantityValue = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  justify-content: center;
  font-family: Onest-SemiBold;
  background: ${(props) => props.theme.palette.background.bg3};
  color: ${(props) => props.theme.palette.text.t1};
`;

interface PlusMinusInteface {
  quantity: number;
  availability: number | null;
}

export const PlusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 24px;
  font-family: Onest-Bold;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  color: ${(props) => props.theme.palette.text.t1};
  background: ${(props) => props.theme.palette.background.bg3};

  cursor: ${(props: PlusMinusInteface) =>
    props.availability === null && props.quantity <= 10
      ? 'pointer'
      : 'not-allowed'};
  @media (max-width: ${WIDTH.mobileMax}) {
  }
`;

export const CLIAvailabilityText = styled.div`
  font: 0.75rem/1rem Onest-Medium;
  color: ${(props) => props.theme.palette.error.main};
  padding: 0 3px 2px;
  text-align: left;
`;

export const CLIPriceItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  span {
    font: 0.75rem/0.75rem Onest-SemiBold;
    color: ${(props) => props.theme.palette.text.t3};
    text-decoration: line-through;
    text-align: right;
  }
`;

export const CLIPriceItem = styled.div`
  flex: 1;
  font-family: Onest-SemiBold;
  font: 1.25rem/1.5rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on;
  overflow: hidden;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex: none;
    display: flex;
    align-items: end;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CLIPreOrder = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 12px;
  > p {
    font-size: 12px;
    line-height: 16px;
    font-family: Onest-Medium;
    color: #808080;
    > span {
      color: #ff8d07;
      font-family: Onest-Medium;
      font-size: 12px;
      line-height: 16px;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0;
  }
`;

export const CLIProduct = styled.div`
  padding: 16px;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 12px;
  }
`;

export const GameNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 8px;
  }
`;
