import Link from 'next/dist/client/link';
import Image from 'next/image';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import {
  CenterFlexContent,
  EmptyCartContainer,
  EmptyCartIllustrationContainer,
  EmptyCartSubHead,
  Title,
} from './EmptyCartStyle';

const EmptyCart = () => {
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const { messages } = useLocale();
  const { your_cart_is_empty_msg, go_ahead_add_msg, go_to_store_msg } =
    messages || {};

  return (
    <CenterFlexContent
      style={{
        height:
          width && width < getNumFromResolutionConstant(WIDTH.tabletMax)
            ? 'calc(100vh - 195px)'
            : 'auto',
      }}
    >
      <EmptyCartContainer>
        <EmptyCartIllustrationContainer>
          <Image
            src="https://static.driffle.com/media-gallery/production/860122fd-2d7e-4b18-92fe-34be7898f5e0_empty-cartpng"
            unoptimized
            priority
            width={
              width && width < getNumFromResolutionConstant(WIDTH.mobileMax)
                ? '168px'
                : '264px'
            }
            height={
              width && width < getNumFromResolutionConstant(WIDTH.mobileMax)
                ? '140px'
                : '216px'
            }
            alt="banner"
          />
        </EmptyCartIllustrationContainer>
        <Title>{your_cart_is_empty_msg}</Title>
        <EmptyCartSubHead>{go_ahead_add_msg}</EmptyCartSubHead>
        <Link passHref prefetch={false} href="/store">
          <a>
            <Button
              width="180px"
              // onClick={() => router.push('/store')}
              ariaLabel="shop on driffle"
              height="48px"
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <MaskIcon
                  url="/icons/store-24.svg"
                  width={'24px'}
                  height="24px"
                  selected={true}
                  color={theme.palette.white.main}
                  margin="0 8px 0 0"
                />
                {go_to_store_msg}
              </span>
            </Button>
          </a>
        </Link>
      </EmptyCartContainer>
    </CenterFlexContent>
  );
};

export default memo(EmptyCart);
