import { useTheme } from "styled-components";
import { DabbaQty, MinusContainer, PlusContainer, QuantityContainer, QuantityValue } from "../CartListItemStyles";
import { MaskIcon } from "../../Icons/Icons";




interface CAProps {
  onDeleteClick: (e: React.MouseEvent) => void;
  onMinusClick: (e: React.MouseEvent) => void;
  onPlusClick: (e: React.MouseEvent) => void;
  availability: number | null;
  quantity: number;
}
export const CartActions = (props: CAProps) => {
  const theme = useTheme();
  return (
    <DabbaQty>
      <MaskIcon
        onClick={props.onDeleteClick}
        style={{ cursor: 'pointer' }}
        url="/icons/delete_v2.svg"
        width={'20px'}
        height="20px"
        selected={true}
        color={theme.palette.text.t2}
        margin="0"
      />
      <QuantityContainer>
        <MinusContainer
          onClick={props.onMinusClick}
          quantity={props.quantity}
          availability={props.availability}
        >
          <MaskIcon
            url="/icons/minus_cart.svg"
            width={'16px'}
            height="16px"
            selected={true}
            color={theme.palette.text.t1}
            margin="0"
          />
        </MinusContainer>
        <QuantityValue>{props.quantity}</QuantityValue>
        <PlusContainer
          onClick={props.onPlusClick}
          quantity={props.quantity}
          availability={props.availability}
        >
          <MaskIcon
            url="/icons/plus_cart.svg"
            width={'16px'}
            height="16px"
            selected={true}
            color={theme.palette.text.t1}
            margin="0"
          />
        </PlusContainer>
      </QuantityContainer>
    </DabbaQty>
  );
};