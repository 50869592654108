import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const CenterFlexContent = styled.div`
  width: 100%;
  // height: calc(100vh - 195px);
  display: flex;
  justify-content: center;
  // margin-top: 3rem;
`;

export const EmptyCartContainer = styled.div`
  border-radius: 6px;
  // background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  // box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
  // padding: 20px;
  margin-top: 70px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 20px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  margin-top: 24px;
  margin-bottom: 8px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 40px;
  }
`;

export const EmptyCartSubHead = styled.div`
  font: 16px/20px Onest-Medium;
  margin-bottom: 24px;
  color: ${(props) => props.theme.palette.text.t2};
`;

export const EmptyCartIllustrationContainer = styled.div``;
