import { useEffect, useState } from 'react';
import { CartItemInterface } from '../../Interface/CartItemInterface';
import { useAppSelector } from '../../redux/hooks';
import CartListItem from '../CartListItem/CartListItem';
import { CartListContainer } from './CartListStyle';

interface Props {
  type: 'cart' | 'checkout';
}

/**
 *
 * @param props
 * Get the cart from the redux cart state.
 * If cart doesn't exist or there are no itms in the cart show nothing added to cart
 * @returns
 */
const CartList = (props: Props) => {
  const { cart } = useAppSelector((state) => state);
  const [items, setItems] = useState<Array<CartItemInterface> | null>(null);

  useEffect(() => {
    if (!cart || cart.cartItems.length === 0) {
      setItems(null);
      return;
    }
    setItems(cart.cartItems);
  }, [cart]);

  const findQuantity = (offerId: number) => {
    if (Array.isArray(cart.cartItems)) {
      const foundObject = cart.cartItems.find((obj, ind) => {
        return obj.offerId === offerId;
      });
      if (foundObject) {
        return foundObject.qty;
      }
    }
    return 0;
  };

  return (
    <CartListContainer>
      {items && items.length > 0
        ? items.map((item, index) => (
            <CartListItem
              key={item.offerId}
              item={item}
              quantity={findQuantity(item.offerId)}
              type={props.type}
            />
          ))
        : null}
    </CartListContainer>
  );
};

export default CartList;
