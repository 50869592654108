import { useRouter } from 'next/router';
import { memo } from 'react';
import { COLORS } from '../../constants/colors';
import { displayCurrencyValue } from '../../helper/helper';
import { useAppSelector } from '../../redux/hooks';
import { saveEventV3 } from '../../utils/eventTracking';
import { BackgroundIcon } from '../Icons/Icons';
import { ToggleButton } from '../ToggleButton/ToggleButton';
import {
  EnableMembershipCardBody,
  EnableMembershipCardBodyList,
  EnableMembershipCardListItem,
  EnableMembershipCardTitle,
  EnableMembershipCardWrapper,
  IllustrationPlus,
  ToggleButtonContainer,
} from './EnableMembershipCardStyle';
import useToggleMembership from './hook/useToggleMembership';

function EnableMembershipCard() {
  const router = useRouter();
  const { status, toggleMembership } = useToggleMembership();
  const cart = useAppSelector((state) => state.cart);
  const global = useAppSelector((state) => state.global);
  return (
    <EnableMembershipCardWrapper status={status}>
      <IllustrationPlus>
        <BackgroundIcon
          url="/icons/plus-illustration.svg"
          height="60px"
          width="60px"
          style={{
            transform: 'rotate(-90deg)',
          }}
        />
      </IllustrationPlus>
      <EnableMembershipCardTitle>
        <h3>
          Save extra{' '}
          <span>
            {displayCurrencyValue(
              Number(cart.subTotal ?? 0) -
                Number(cart?.drifflePlusPrice?.subTotal ?? 0),
              global.locationDetails?.currency_symbol,
              global.locationDetails?.multiplier
            )}
          </span>
          , join Driffle{' '}
          <BackgroundIcon
            url="/logo/plus-logo.svg"
            width="32px"
            height="18px"
            margin="0"
          />
        </h3>
      </EnableMembershipCardTitle>
      <EnableMembershipCardBody>
        <EnableMembershipCardBodyList>
          <EnableMembershipCardListItem>
            <div>
              <BackgroundIcon
                url={`/icons/purple-tick-24.svg`}
                height="18px"
                width="18px"
                margin="0"
              />
            </div>
            <p>Upto 10% OFF on digital keys</p>
          </EnableMembershipCardListItem>
          <EnableMembershipCardListItem>
            <div>
              <BackgroundIcon
                url={`/icons/purple-tick-24.svg`}
                height="18px"
                width="18px"
                margin="0"
              />
            </div>
            <p>Access to exclusive sale events</p>
          </EnableMembershipCardListItem>

          <EnableMembershipCardListItem>
            <div>
              <BackgroundIcon
                url={`/icons/purple-tick-24.svg`}
                height="18px"
                width="18px"
                margin="0"
              />
            </div>
            <p>Fastest pre-orders delivery </p>
          </EnableMembershipCardListItem>

          <EnableMembershipCardListItem>
            <div>
              <BackgroundIcon
                url={`/icons/purple-tick-24.svg`}
                height="18px"
                width="18px"
                margin="0"
              />
            </div>
            <p>Priority support</p>
          </EnableMembershipCardListItem>
        </EnableMembershipCardBodyList>

        <ToggleButtonContainer>
          <ToggleButton
            checked={status}
            onClick={() => {
              toggleMembership(!status);
              saveEventV3({
                action: 'click',
                category: 'plus_box',
                label: 'toggle_membership',
                properties: String(!status),
                value: [],
                from: router,
              });
            }}
            checkedColor="#fff"
            unCheckedColor="#fff"
            checkedBackgroundColor={COLORS.drifflePlus}
            unCheckedBackgroundColor={COLORS.drifflePlus + '80'}
          />
        </ToggleButtonContainer>
      </EnableMembershipCardBody>
    </EnableMembershipCardWrapper>
  );
}

export default memo(EnableMembershipCard);
