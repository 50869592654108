import styled from 'styled-components';

export function ToggleButton(props: {
  checked: boolean;
  disabled?: boolean;
  onClick: any;
  unCheckedBackgroundColor?: string;
  checkedBackgroundColor?: string;
  unCheckedBorderColor?: string;
  checkedBorderColor?: string;
  unCheckedColor?: string;
  checkedColor?: string;
}) {
  return (
    <Label style={props.disabled ? { cursor: 'default' } : {}}>
      <CheckBoxInput
        disabled={props.disabled}
        onChange={props.onClick}
        checked={props.checked}
        type="checkbox"
        style={props.disabled ? { cursor: 'default' } : {}}
        unCheckedBackgroundColor={props.unCheckedBackgroundColor}
        checkedBackgroundColor={props.checkedBackgroundColor}
        unCheckedBorderColor={props.unCheckedBorderColor}
        checkedBorderColor={props.checkedBorderColor}
        unCheckedColor={props.unCheckedColor}
        checkedColor={props.checkedColor}
      ></CheckBoxInput>
      <CheckBoxSpan
        checked={props.checked}
        style={props.disabled ? { cursor: 'default' } : {}}
        unCheckedBackgroundColor={props.unCheckedBackgroundColor}
        checkedBackgroundColor={props.checkedBackgroundColor}
        unCheckedBorderColor={props.unCheckedBorderColor}
        checkedBorderColor={props.checkedBorderColor}
        unCheckedColor={props.unCheckedColor}
        checkedColor={props.checkedColor}
      ></CheckBoxSpan>
    </Label>
  );
}

const Label = styled.label`
  width: 60px;
  height: 34px;
  transform: scale(0.6) translate(-40%, -30%);
  cursor: pointer;
`;

interface StyleProps {
  unCheckedBackgroundColor?: string;
  checkedBackgroundColor?: string;
  unCheckedBorderColor?: string;
  checkedBorderColor?: string;
  unCheckedColor?: string;
  checkedColor?: string;
  checked: boolean;
}

const CheckBoxSpan = styled.span<StyleProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.unCheckedBackgroundColor ?? '#4b4b4b'
      : props.unCheckedBackgroundColor ?? '#ccc'};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  border: 2px solid ${(props) => props.unCheckedBorderColor ?? 'transparent'};
  &:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 3px;
    background-color: ${(props) =>
      props.checked
        ? props.checkedColor ?? '#fff'
        : props.unCheckedColor ?? '#fff'};

    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CheckBoxInput = styled.input<StyleProps>`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${CheckBoxSpan} {
    background-color: ${(props) =>
      props.checkedBackgroundColor ?? props.theme.palette.primary.main};
    border-color: ${(props) => props.checkedBorderColor ?? 'transparent'};
  }
  &:focus + ${CheckBoxSpan} {
    box-shadow: 0 0 1px ${(props) => props.theme.palette.primary.main};
  }
  &:checked + ${CheckBoxSpan}:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
`;
