import Link from 'next/link';
import { useTheme } from 'styled-components';
import { CartItemInterface } from '../../Interface/CartItemInterface';
import { useLocale } from '../../contexts/LocalizationContext';
import DigitalKeyTile from '../PDPPage/components/DigitalKeyTile/DigitalKeyTile';
import ProductTypeTag from '../ProductTypeTag/ProductTypeTag';
import {
  CLIAvailabilityText,
  CLIContainer,
  CLIGameName,
  CLIImageContainer,
  CLIPriceContainer,
  CLIPriceItem,
  CLIPriceItemContainer,
  CLIProduct,
  CLIProductData,
  CLIProductDetail,
  CLIProductImage,
  CLIProductTagContainer,
  CLIStoreName,
  GameNameContainer,
  GameNameWrapper,
  RegionTag,
} from './CartListItemStyles';

import React, { useMemo } from 'react';
import { WIDTH } from '../../constants/screenResolution';
import {
  displayCurrencyValue,
  getRegionFromRegionId,
} from '../../helper/helper';
import { useCartAction } from '../../hooks/cart/useCartAction';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { ellipseString } from '../../utils/sellerStatsUtils';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import { PreOrder } from '../ProductListItem/ProductListItemStyle';
import { CartActions } from './components/CartActions';

interface CLIProps {
  item: CartItemInterface;
  quantity: number;
  type: 'cart' | 'checkout';
  discount?: {
    productId: number;
    discount: number;
  };
  setReferredPromo?: React.Dispatch<any>;
}

//CartListItem -- CLI
const CartListItem = (props: CLIProps) => {
  const theme = useTheme();
  const { messages } = useLocale();
  const cart = useAppSelector((state) => state.cart);
  const user = useAppSelector((state) => state.user);
  const { locationDetails } = useAppSelector((state) => state.global);
  const { only_msg, available_msg, oos_msg } = messages || {};
  const { width } = useWindowDimensions();

  const { onDeleteClick, onMinusClick, onPlusClick } = useCartAction({
    quantity: props.quantity,
    item: props.item,
    setReferredPromo: props.setReferredPromo,
  });

  const itemTotal = useMemo(() => {
    return user.subscription === 'active' || cart.subscription
      ? props?.item?.plusDiscountedPrice || props.item.price
      : props.item.price;
  }, [
    cart.subscription,
    props.item?.plusDiscountedPrice,
    props.item.price,
    user.subscription,
  ]);

  return (
    <CLIContainer>
      <CLIStoreName>
        <p>
          You are buying from{' '}
          <Link
            passHref
            href={
              '/vendor/' +
              props.item.merchant.merchantId +
              `${props.item.offerType == 1 ? '?ref=1' : ''}`
            }
          >
            <a>
              <span>
                {' '}
                {props.item.merchant.name
                  ? ellipseString(props.item.merchant.name, 20)
                  : '-'}
              </span>
            </a>
          </Link>
        </p>
      </CLIStoreName>
      <CLIProduct>
        <CLIProductData>
          <Link passHref href={`/${props.item.product.productSlug}`}>
            <a>
              <CLIImageContainer>
                <CLIProductImage src={props.item.product.image} />
              </CLIImageContainer>
            </a>
          </Link>
          <CLIProductDetail>
            <GameNameWrapper>
              <GameNameContainer>
                <Link href={`/${props.item.product.productSlug}`} passHref>
                  <a target="_blank">
                    <CLIGameName>{props.item.product.title} </CLIGameName>
                  </a>
                </Link>
                {width &&
                  width > getNumFromResolutionConstant(WIDTH.mobileMax) && (
                    <CLIProductTagContainer>
                      <ProductTypeTag
                        productTypes={String(
                          props?.item?.product.productTypes
                        ).split(',')}
                        fontSize={0.625}
                        lineHeight={1}
                        padding="4px 6px"
                      />
                      <DigitalKeyTile fontSize={0.625} lineHeight={1} />
                    </CLIProductTagContainer>
                  )}
              </GameNameContainer>
              <RegionTag>
                {getRegionFromRegionId(props.item.product.regionId)}
              </RegionTag>
            </GameNameWrapper>
            {width &&
              width <= getNumFromResolutionConstant(WIDTH.mobileMax) &&
              (typeof props.item.product.isPreReleased === 'number' &&
              props.item.product.isPreReleased === 1 ? (
                <PreOrder
                  style={
                    props.type === 'checkout' ? { marginBottom: '4px' } : {}
                  }
                >
                  {' '}
                  <MaskIcon
                    url="/icons/event_upcoming.svg"
                    width={'14px'}
                    height="14px"
                    selected={true}
                    color={theme.palette.warning.main}
                    margin="0"
                  />
                  Pre-order
                </PreOrder>
              ) : null)}

            <div>
              {width &&
                width > getNumFromResolutionConstant(WIDTH.mobileMax) &&
                (typeof props.item.product.isPreReleased === 'number' &&
                props.item.product.isPreReleased === 1 ? (
                  <PreOrder
                    style={
                      props.type === 'checkout' ? { marginBottom: '4px' } : {}
                    }
                  >
                    {' '}
                    <MaskIcon
                      url="/icons/event_upcoming.svg"
                      width={'14px'}
                      height="14px"
                      selected={true}
                      color={theme.palette.warning.main}
                      margin="0"
                    />
                    Pre-order
                  </PreOrder>
                ) : null)}
              {width &&
              width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
                <CLIPriceContainer
                  isWarning={
                    props.item.availability !== null &&
                    props.item.availability !== undefined &&
                    props.item.availability >= 0
                  }
                >
                  <div>
                    {props.item.availability !== null &&
                    props.item.availability !== undefined ? (
                      props.item.availability > 0 ? (
                        <CLIAvailabilityText>
                          {only_msg} {props.item.availability}{' '}
                          {props.item.availability > 1
                            ? 'items are'
                            : 'item is'}{' '}
                          {available_msg}
                        </CLIAvailabilityText>
                      ) : (
                        <CLIAvailabilityText>{oos_msg}</CLIAvailabilityText>
                      )
                    ) : null}

                    <CartActions
                      onMinusClick={onMinusClick}
                      onPlusClick={onPlusClick}
                      onDeleteClick={onDeleteClick}
                      availability={props.item.availability}
                      quantity={props.quantity}
                    />
                  </div>
                  <CLIPriceItemContainer>
                    {(user.subscription === 'active' || cart.subscription) &&
                    props.item.price > itemTotal ? (
                      <span>
                        {displayCurrencyValue(
                          props.item.price,
                          locationDetails?.currency_symbol,
                          locationDetails?.multiplier
                        )}
                      </span>
                    ) : null}
                    <CLIPriceItem>
                      {(user.subscription === 'active' || cart.subscription) &&
                      props.item.price > itemTotal ? (
                        <BackgroundIcon
                          url="/logo/plus-logo.svg"
                          width="32px"
                          height="20px"
                          margin="0"
                        />
                      ) : null}
                      {displayCurrencyValue(
                        itemTotal,
                        locationDetails?.currency_symbol,
                        locationDetails?.multiplier
                      )}
                    </CLIPriceItem>
                  </CLIPriceItemContainer>
                </CLIPriceContainer>
              ) : (
                <CLIProductTagContainer>
                  <ProductTypeTag
                    productTypes={String(
                      props?.item?.product.productTypes
                    ).split(',')}
                    fontSize={0.625}
                    lineHeight={1}
                    padding="4px 6px"
                  />
                  <DigitalKeyTile fontSize={0.625} lineHeight={1} />
                </CLIProductTagContainer>
              )}
            </div>
          </CLIProductDetail>
        </CLIProductData>
        {width && width <= getNumFromResolutionConstant(WIDTH.mobileMax) && (
          <CLIPriceContainer
            isWarning={
              props.item.availability !== null &&
              props.item.availability !== undefined &&
              props.item.availability >= 0
            }
          >
            <div>
              {props.item.availability !== null &&
              props.item.availability !== undefined ? (
                props.item.availability > 0 ? (
                  <CLIAvailabilityText>
                    {only_msg} {props.item.availability}{' '}
                    {props.item.availability > 1 ? 'items are' : 'item is'}{' '}
                    {available_msg}
                  </CLIAvailabilityText>
                ) : (
                  <CLIAvailabilityText>{oos_msg}</CLIAvailabilityText>
                )
              ) : null}

              <CartActions
                onMinusClick={onMinusClick}
                onPlusClick={onPlusClick}
                onDeleteClick={onDeleteClick}
                availability={props.item.availability}
                quantity={props.quantity}
              />
            </div>
            <CLIPriceItemContainer>
              {(user.subscription === 'active' || cart.subscription) &&
              props.item.price > itemTotal ? (
                <span>
                  {displayCurrencyValue(
                    props.item.price,
                    locationDetails?.currency_symbol,
                    locationDetails?.multiplier
                  )}
                </span>
              ) : null}
              <CLIPriceItem>
                {(user.subscription === 'active' || cart.subscription) &&
                props.item.price > itemTotal ? (
                  <BackgroundIcon
                    url="/logo/plus-logo.svg"
                    width="32px"
                    height="20px"
                    margin="0"
                  />
                ) : null}
                {displayCurrencyValue(
                  itemTotal,
                  locationDetails?.currency_symbol,
                  locationDetails?.multiplier
                )}
              </CLIPriceItem>
            </CLIPriceItemContainer>
          </CLIPriceContainer>
        )}
      </CLIProduct>
    </CLIContainer>
  );
};

export default CartListItem;
