import { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import { displayCurrencyValue } from '../../helper/helper';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import {
  CartButtonContainer,
  SummaryContainer,
  SummarySuperContainer,
  SummaryWrapper,
  Title,
  TotalContainer,
  TotalTitle,
  TotalValue,
} from './CartSummaryStyle';

import Link from 'next/link';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import MobileCartPromo from '../CartPromo/MobileCartPromo';
import LoaderCircle from '../LoaderDots/LoaderCircle';

import MembershipPromotionStrip from '../Membership/MembershipPromotionStrip/MembershipPromotionStrip';
import PriceNotFinalText from '../PriceNotFinalText/PriceNotFinalText';
import TnCPopup from './TnCPopup';

interface Props {
  onButtonClick: () => void;
  buttonMessage: ReactElement;
  paymentLoading?: boolean;
  onCartPromoBoxClick?: () => void;
}

const CartSummary = ({
  onButtonClick,
  buttonMessage,
  paymentLoading = false,
  onCartPromoBoxClick,
}: Props) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const cart = useAppSelector((state) => state.cart);
  const global = useAppSelector((state) => state.global);
  const user = useAppSelector((state) => state.user);
  const [openTnCPopup, setOpenTnCPopup] = useState(false);
  const { messages } = useLocale();

  const { summary_msg, proceed_to_checkout_msg, your_cart_total_msg } =
    messages || {};

  const cartSubTotal = useMemo(() => {
    return user.subscription === 'active' || cart.subscription
      ? cart?.drifflePlusPrice?.subTotal || cart.subTotal
      : cart.subTotal;
  }, [
    cart?.drifflePlusPrice?.subTotal,
    cart.subTotal,
    cart.subscription,
    user.subscription,
  ]);

  const savingAmount = useMemo(() => {
    return (
      Number(cart.subTotal ?? 0) - Number(cart?.drifflePlusPrice?.subTotal ?? 0)
    );
  }, [cart?.drifflePlusPrice?.subTotal, cart.subTotal]);

  const handleTnCPopupState = useCallback(() => setOpenTnCPopup(false), []);
  const handleButtonClick = useCallback(() => {
    if (!paymentLoading) {
      onButtonClick();
    }
  }, [onButtonClick, paymentLoading]);

  return (
    <>
      <SummarySuperContainer>
        {width && width <= getNumFromResolutionConstant(WIDTH.tabletMax) && (
          <Title>{summary_msg}</Title>
        )}
        <SummaryWrapper>
          {savingAmount > 0 && user.subscription !== 'no' ? (
            <MembershipPromotionStrip
              selectedPriceType={
                user.subscription === 'active' || cart.subscription
                  ? 'membership'
                  : 'regular'
              }
              position="top"
              savingAmount={savingAmount}
            />
          ) : null}
          <SummaryContainer
            type="cart"
            style={{
              borderRadius:
                savingAmount > 0 && user.subscription !== 'no'
                  ? '0 0 8px 8px '
                  : '8px',
              border: `1px solid ${theme.palette.border.b1}`,
              borderTop:
                savingAmount > 0 && user.subscription !== 'no'
                  ? 'none'
                  : `1px solid ${theme.palette.border.b1}`,
            }}
          >
            <TotalContainer>
              <TotalTitle>{your_cart_total_msg}</TotalTitle>
              <TotalValue>
                {displayCurrencyValue(
                  cartSubTotal,
                  global.locationDetails?.currency_symbol,
                  global.locationDetails?.multiplier
                )}
                <PriceNotFinalText />
              </TotalValue>
            </TotalContainer>
            {onCartPromoBoxClick && (
              <div
                style={{ marginBottom: '16px', cursor: 'pointer' }}
                onClick={onCartPromoBoxClick}
              >
                <MobileCartPromo />
              </div>
            )}
            <CartButtonContainer>
              <Link passHref href="/checkout">
                <a>
                  <Button
                    width="100%"
                    height="48px"
                    ariaLabel="continue"
                    onClick={handleButtonClick}
                  >
                    {paymentLoading ? (
                      <LoaderCircle />
                    ) : (
                      <>
                        <span>{proceed_to_checkout_msg}</span>{' '}
                        <MaskIcon
                          selected
                          color="#fff"
                          url="/icons/direction-up-24.svg"
                          margin="0"
                          height="24px"
                          width="24px"
                        />
                      </>
                    )}
                  </Button>
                </a>
              </Link>
            </CartButtonContainer>
          </SummaryContainer>
        </SummaryWrapper>
      </SummarySuperContainer>
      {openTnCPopup && (
        <TnCPopup open={openTnCPopup} handlePopupState={handleTnCPopupState} />
      )}
    </>
  );
};

export default memo(CartSummary);
