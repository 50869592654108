import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const MobileCartPromoContainer = styled.div`
  background: ${COLORS.purple2};
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.04);
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 16px 0;
  }
`;

export const HaveCouponText = styled.span`
  font: 14px/16px Onest-Medium;
  color: ${COLORS.purple};
`;

export const ApplyCouponText = styled.div`
  font: 16px/20px Onest-Bold;
  color: ${COLORS.purple};
`;
