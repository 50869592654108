import { Skeleton } from '@mui/material';
import axios from 'axios';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { ProductDetailsInterface } from '../../Interface/ProductDetailsInterface';
import CartList from '../../components/CartList/CartList';
import CheckoutStatusBar from '../../components/CheckoutStatusBar/CheckoutStatusBar';
import CartSummary from '../../components/CheckoutSummary/CartSummary';
import EmptyCart from '../../components/EmptyCart/EmptyCart';
import Header from '../../components/Header/Header';
import { MaskIcon } from '../../components/Icons/Icons';
import LoaderCircle from '../../components/LoaderDots/LoaderCircle';
import MobileCartHeader from '../../components/MobileCartHeader/MobileCartHeader';
import MobileHeader from '../../components/MobileHeader/MobileHeader';
import MobileNav from '../../components/MobileNav/MobileNav';

const MobileSummaryCartSticky = dynamic(
  () => import('../../components/MobileSummarySticky/MobileSummaryCartSticky'),
  { ssr: false }
);

const MysteryBox = dynamic(() => import('../../components/MystryBox/Mystery'), {
  ssr: false,
});

import { apiConstants } from '../../constants/apiConstants';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  BodyContainer,
  CartSummaryContainer,
  MainWrapper,
  MobileBodyContainer,
  SummaryContainer,
  Title,
} from '../../styles/pageStyles/cartStyle';
import { saveEventV3 } from '../../utils/eventTracking';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';

const RecombeeRecommendedProducts = dynamic(
  () => import('../../components/SimilarProducts/RecombeeRecommendedProducts'),
  { ssr: false }
);

import dynamic from 'next/dynamic';
import CartTnc from '../../components/CartTnc/CartTnc';
import EnableMembershipCard from '../../components/EnableMembershipCard/EnableMembershipCard';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useESim } from '../../contexts/ESimContext';
import { useLocale } from '../../contexts/LocalizationContext';
import useFetchRecommendations from '../../hooks/recommendations/useFetchUserRecommendations';
import { fetchCarts } from '../../redux/cartSlice';
import { getProductRecommendationType } from '../../utils/abTest';
import { getUserInfoSSR } from '../../utils/cf';
import {
  WE_GET_CART_DETAILS_OBJECT,
  WE_GET_CART_ITEMS,
  WE_USER_EVENT_CART_PAGE_VIEWED,
} from '../../utils/we';

export interface MysteryBoxData {
  meta: Object;
  product: ProductDetailsInterface;
}

const Cart = () => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();
  const { isESim } = useESim();

  const { messages } = useLocale();
  const {
    server_error_msg,
    cart_title_msg,
    checkout_msg,
    summary_msg,
    my_cart_msg,
    by_proceeding_through_checkout_msg,
    inspired_by_your_picks_msg,
    terms_of_service_msg,
    refund_policy_msg,
    privacy_policy_msg,
  } = messages || {};

  const [loading, setLoading] = useState<boolean>(true);
  const [serverError, setServerError] = useState<boolean>(false);
  const theme = useContext(ThemeContext);
  const [mbData, setMbData] = useState<MysteryBoxData | null>(null);
  const [mbDataLoaded, setMbDataLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { cart } = useAppSelector((state) => state);
  const user = useAppSelector((state) => state.user);
  const global = useAppSelector((state) => state.global);
  const { width } = useWindowDimensions();

  //fetching trustpilot reviews
  // const trustpilotReviews = useTrustpilotReviews();
  // useEffect(() => {
  //   trustpilotReviews.fetchReviews();
  // }, []);

  //fetching mystery box data
  const fetchMysteryBoxData = useCallback(async () => {
    await axios
      .get(apiConstants.server + '/public/cart/upsell?location=cart', {
        withCredentials: true,
      })
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.data) {
            setMbData(response?.data?.data);
          } else {
            setMbData(null);
          }
          setMbDataLoaded(true);
        }
      })
      .catch((error) => {
        setMbData(null);
      });
  }, []);

  useEffect(() => {
    fetchMysteryBoxData();
  }, [fetchMysteryBoxData]);

  const ISMystryBoxInCart = useMemo(() => {
    if (!!mbData && mbData.product?.offers?.length > 0) {
      return cart.cartItems.some(
        (item) => item.offerId === mbData.product?.offers[0].offerId
      );
    }
    return false;
  }, [cart, mbData]);

  useEffect(() => {
    if (mbDataLoaded) {
      if (!!mbData) {
        saveEventV3({
          action: 'load',
          category: 'mystery_box',
          label: 'success',
          value: [mbData?.product?.slug],
          properties: '',
          from: router,
        });
      } else {
        saveEventV3({
          action: 'load',
          category: 'mystery_box',
          label: 'failure',
          value: [''],
          properties: '',
          from: router,
        });
      }
    }
  }, [mbData, mbDataLoaded]);

  useEffect(() => {
    if (cart.isFetching === 'fetched') {
      setLoading(false);
    } else if (cart.isFetching === 'fetching') {
      dispatch(fetchCarts(isESim));
    }
  }, [cart.isFetching, dispatch, isESim]);

  useEffect(() => {
    if (cart.isFetching === 'fetched' && cleverTap) {
      if (global?.locationDetails?.currency && cart?.subTotal) {
        WE_USER_EVENT_CART_PAGE_VIEWED(
          {
            items: WE_GET_CART_ITEMS(cart),
            currency: global?.locationDetails?.currency,
            cartTotal: cart.subTotal,
            cartDetails: WE_GET_CART_DETAILS_OBJECT(cart),
          },
          cleverTap
        );
      }
    } else if (cart.isFetching === 'fetching') {
      dispatch(fetchCarts(isESim));
    }
  }, [cart, cleverTap, global?.locationDetails?.currency, isESim]);

  // useEffect(() => {
  //   axios
  //     .get(apiConstants.server + '/public/promo/all', {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       if (
  //         response.data.data &&
  //         response.data.data.customer &&
  //         response.data.data.other
  //       )
  //         setPromos(response.data.data);
  //     })
  //     .catch((error) => {
  //       setPromos({
  //         customer: [],
  //         other: [],
  //       });
  //     });
  // }, []);

  const recommendationsForYou = useFetchRecommendations({
    scenario: 'inspired_by_your_picks',
    fromPage: 'cart',
    limit: 12,
  });

  useEffect(() => {
    if (
      global.deviceId &&
      global.locationDetails?.country_code &&
      recommendationsForYou.recombeeRecommendations === 'loading' &&
      typeof window !== 'undefined'
    ) {
      recommendationsForYou.getRecommendation();
      saveEventV3({
        action: 'load',
        category: 'cart',
        from: router,
        label: 'section',
        properties: '0',
        value: ['inspired_by_your_picks'],
      });
    }
  }, [
    global.deviceId,
    global.locationDetails?.country_code,
    recommendationsForYou,
    router,
  ]);

  return (
    <>
      <Head>
        <title>Cart - Driffle</title>
      </Head>

      {loading ? (
        <MainWrapper>
          <BodyContainer
            style={{
              minHeight: '100vh',
              display: 'grid',
              placeContent: 'center',
            }}
          >
            {serverError ? <div> {server_error_msg}</div> : <LoaderCircle />}
          </BodyContainer>
          <MobileBodyContainer>
            <div
              style={{
                minHeight: '100vh',
                height: '100%',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              {serverError ? <div>{server_error_msg}</div> : <LoaderCircle />}
            </div>
          </MobileBodyContainer>
        </MainWrapper>
      ) : (
        <>
          {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) && (
            <MainWrapper>
              <Header type={cart.cartItems.length > 0 ? 'cart' : undefined}>
                <CheckoutStatusBar currentStep={1} />
              </Header>
              <BodyContainer>
                <SummaryContainer>
                  {cart.cartItems.length > 0 ? (
                    <>
                      <div style={{ flex: '1' }}>
                        <Title>{my_cart_msg}</Title>
                        {!mbDataLoaded ? (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={80}
                            style={{
                              background: theme.palette.background.bg3,
                              borderRadius: '8px',
                              marginBottom: '16px',
                            }}
                          />
                        ) : (
                          <>
                            {!!mbData &&
                              mbData.product?.offers?.length > 0 &&
                              !ISMystryBoxInCart && (
                                <MysteryBox
                                  meta={mbData.meta}
                                  product={mbData.product}
                                />
                              )}
                          </>
                        )}
                        <CartList type="cart" />
                      </div>
                      <CartSummaryContainer>
                        <Title>{summary_msg}</Title>
                        <CartSummary
                          onButtonClick={() => {
                            saveEventV3({
                              category: 'cart',
                              action: 'click',
                              label: 'proceed_to_checkout',
                              properties: '',
                              value: [],
                              from: router,
                            });
                          }}
                          buttonMessage={
                            <>
                              <MaskIcon
                                url="/icons/store-24.svg"
                                width={'24px'}
                                height="24px"
                                color={'#fff'}
                                selected={true}
                                margin="0 10px 0 0"
                              />
                              {checkout_msg}
                            </>
                          }
                        />
                        {user.subscription === 'active' ||
                        user.subscription === 'no' ||
                        Number(cart?.subTotal ?? 0) <=
                          Number(
                            cart?.drifflePlusPrice?.subTotal ?? 0
                          ) ? null : (
                          <EnableMembershipCard />
                        )}
                        <CartTnc />
                        {/* <SeeOurTrustPilotReviewSection
                          reviews={trustpilotReviews.data}
                        /> */}
                      </CartSummaryContainer>
                    </>
                  ) : (
                    <EmptyCart />
                  )}
                </SummaryContainer>
                {getProductRecommendationType() === 'recombee' ? (
                  <div style={{ width: '100%', paddingTop: '16px' }}>
                    {recommendationsForYou.recombeeRecommendations ===
                      'loading' ||
                    !recommendationsForYou.recombeeRecommendations
                      ? null
                      : recommendationsForYou.recombeeRecommendations && (
                          <RecombeeRecommendedProducts
                            products={
                              recommendationsForYou.recombeeRecommendations
                                ?.list
                            }
                            recommendationId={
                              recommendationsForYou.recombeeRecommendations?.id
                            }
                            title={
                              inspired_by_your_picks_msg ||
                              'Inspired by your picks'
                            }
                            titleName={'inspired_by_your_picks'}
                          />
                        )}
                  </div>
                ) : null}
              </BodyContainer>
            </MainWrapper>
          )}
          {width && width <= getNumFromResolutionConstant(WIDTH.tabletMax) && (
            <div>
              {cart.cartItems.length > 0 ? (
                <MobileCartHeader
                  title={cart_title_msg ?? ''}
                  clickAction={() => {
                    router.push('/');
                  }}
                  type="cart"
                />
              ) : (
                <MobileHeader />
              )}
              <MobileBodyContainer>
                <div
                  style={{
                    padding: '64px 0',
                    paddingBottom: '0',
                  }}
                >
                  {cart.cartItems.length > 0 ? (
                    <>
                      <div style={{ padding: '0 16px' }}>
                        {!mbDataLoaded ? (
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={80}
                            style={{
                              background: theme.palette.background.bg3,
                              borderRadius: '8px',
                              marginBottom: '16px',
                            }}
                          />
                        ) : (
                          <>
                            {!!mbData &&
                              mbData.product?.offers?.length > 0 &&
                              !ISMystryBoxInCart && (
                                <MysteryBox
                                  meta={mbData.meta}
                                  product={mbData.product}
                                />
                              )}
                          </>
                        )}
                        <CartList type="cart" />
                      </div>

                      <div style={{ padding: '16px 16px  0 16px' }}>
                        <CartSummary
                          onButtonClick={() => {
                            saveEventV3({
                              action: 'click',
                              category: 'cart_summary',
                              label: 'proceed_to_checkout',
                              properties: '',
                              value: [],
                              from: router,
                            });
                            router.push('/checkout');
                          }}
                          buttonMessage={
                            <>
                              <MaskIcon
                                url="/icons/store-24.svg"
                                width={'24px'}
                                height="24px"
                                color={'#fff'}
                                selected={true}
                                margin="0 10px 0 0"
                              />
                              {checkout_msg}
                            </>
                          }
                        />
                        <div style={{ marginBottom: '16px' }} />
                        {user.subscription === 'active' ||
                        user.subscription === 'no' ||
                        Number(cart?.subTotal ?? 0) <=
                          Number(
                            cart?.drifflePlusPrice?.subTotal ?? 0
                          ) ? null : (
                          <EnableMembershipCard />
                        )}
                        <CartTnc />
                      </div>

                      {getProductRecommendationType() === 'recombee' ? (
                        <div
                          style={{
                            width: '100%',
                            paddingTop: '12px',
                          }}
                        >
                          <div
                            style={{
                              width: 'calc(100% - 32px)',
                              margin: '0 auto 24px',
                            }}
                          />
                          {recommendationsForYou.recombeeRecommendations ===
                            'loading' ||
                          !recommendationsForYou.recombeeRecommendations ? null : (
                            <RecombeeRecommendedProducts
                              products={
                                recommendationsForYou.recombeeRecommendations
                                  ?.list
                              }
                              recommendationId={
                                recommendationsForYou.recombeeRecommendations
                                  ?.id
                              }
                              title={
                                inspired_by_your_picks_msg ||
                                'Inspired by your picks'
                              }
                              titleName={'inspired_by_your_picks'}
                            />
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <EmptyCart />
                  )}
                </div>
              </MobileBodyContainer>
              {cart && cart.cartItems.length > 0 ? (
                <MobileSummaryCartSticky reviews={null} />
              ) : (
                <MobileNav />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Cart;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
