import dynamic from 'next/dynamic';
import React from 'react';
import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';
import NumberCircle from '../NumberCircle/NumberCircle';
import {
  BarContainer,
  CheckIconContainer,
  StatusBarContainer,
  StatusContainer,
  StatusTextContainer,
} from './CheckoutStatusBarStyle';
const Link = dynamic(() => import('next/link'), {
  ssr: false,
});
// import { CheckoutStatusMessage } from './messages';
import { useRouter } from 'next/router';
import { useLocale } from '../../contexts/LocalizationContext';

interface Props {
  currentStep: number;
  issue?: boolean;
}

const steps = ['Cart', 'Checkout', 'Redeem'];

const CheckoutStatusBar = (props: Props) => {
  const { messages } = useLocale();
  const router = useRouter();
  const { cart_msg, checkout_msg, redeem_msg } = messages || {};

  const [activeStep, setActiveStep] = React.useState(props.currentStep);
  return router.pathname === '/plus/checkout' ? null : (
    <>
      <BarContainer>
        <StatusBarContainer>
          <Link href="/cart" passHref>
            <a>
              <StatusContainer>
                <NumberCircle
                  number={
                    props.currentStep > 1 ? (
                      <CheckIconContainer>
                        <MaskIcon
                          url="/icons/check-long.svg"
                          selected={true}
                          color={COLORS.black}
                          width="17px"
                          height="13px"
                          margin="0 0 0 0"
                        />
                      </CheckIconContainer>
                    ) : (
                      <div>1</div>
                    )
                  }
                  issue={props.currentStep === 1 && props.issue}
                  selected={props.currentStep >= 1}
                />
                <StatusTextContainer
                  issue={props.currentStep === 1 && props.issue}
                  selected={props.currentStep >= 1}
                >
                  {cart_msg}
                </StatusTextContainer>
              </StatusContainer>
            </a>
          </Link>
          <div
            style={{
              flexGrow: '1',
              margin: '12px',
              borderBottom: `2px solid ${
                props.currentStep >= 2 ? COLORS.white : '#4D4D4D'
              }`,
            }}
          />
          <StatusContainer>
            <NumberCircle
              number={
                props.currentStep > 2 ? (
                  <div style={{ display: 'flex' }}>
                    <MaskIcon
                      url="/icons/check-long.svg"
                      selected={true}
                      color={COLORS.black}
                      width="17px"
                      height="13px"
                      margin="0 0 0 0"
                    />
                  </div>
                ) : (
                  <div>2</div>
                )
              }
              issue={props.currentStep === 2 && props.issue}
              selected={props.currentStep >= 2}
            />
            <StatusTextContainer
              issue={props.currentStep === 2 && props.issue}
              selected={props.currentStep >= 2}
            >
              {checkout_msg}
            </StatusTextContainer>
          </StatusContainer>
          <div
            style={{
              flexGrow: '1',
              margin: '12px',
              borderBottom: `2px solid ${
                props.currentStep >= 3 ? COLORS.white : '#4D4D4D'
              }`,
            }}
          />
          <StatusContainer>
            <NumberCircle
              number={<div>3</div>}
              selected={props.currentStep == 3}
              issue={props.currentStep === 3 && props.issue}
            />
            <StatusTextContainer
              issue={props.currentStep == 3 && props.issue}
              selected={props.currentStep == 3}
            >
              {redeem_msg}
            </StatusTextContainer>
          </StatusContainer>
        </StatusBarContainer>
      </BarContainer>
    </>
  );
};

export default CheckoutStatusBar;
