import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const MainWrapper = styled.div`
  background: ${(props) => props.theme.palette.background.bg2};
  min-height: 100vh;
  height: auto;
  overflow-y: auto;
  @media (max-width: ${WIDTH.tabletMax}) {
    min-height: auto;
  }
`;

export const BodyContainer = styled.div`
  max-width: 962px;
  position: relative;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 40px;
  min-height: calc(100vh - 400px);
  scroll-behavior: smooth;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 20px 20px;
    display: none;
    height: 100%;
  }
`;

export const MobileBodyContainer = styled.div`
  display: none;
  min-height: calc(100vh - 72px);
  @media (max-width: ${WIDTH.tabletMax}) {
    display: block;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 16px;
  font-family: Onest-SemiBold;
`;

export const SummaryContainer = styled.div`
  display: flex;
  padding-top: 40px;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
  }
`;

export const MobileStatusContainer = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MobileCartPromoContainer = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
`;

export const CartSummaryContainer = styled.div`
  position: sticky;
  top: 100px;
  flex: 0 1 360px;
  max-width: 343px;
  align-self: flex-start;
  margin-left: 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex: 0 1 360px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    display: none;
  }
`;

export const CartCount = styled.div`
  height: 32px;
  width: 32px;
  background: ${COLORS.gray1};
  color: ${COLORS.black};
  font: 16px/16px Onest-Bold;
  font-family: Onest-Bold;
  border-radius: 50%;
  display: grid;
  place-items: center;

  @media (max-width: ${WIDTH.mobileMax}) {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    font: 16px/16px Onest-Bold;
    font-family: Onest-Bold;
  }
`;

export const BlackFridayContainer = styled.div`
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background: #000;
  cursor: pointer;
  margin-top: 16px;

  & > div {
    display: flex;
    border-radius: 12px;
    align-items: center;
    gap: 7px;
    color: #fff;
    padding: 8px;
    background: linear-gradient(
      92deg,
      rgba(115, 42, 158, 0.5) 1.31%,
      rgba(72, 57, 153, 0.58) 109.31%
    );
    & p {
      font-size: 14px;
      max-width: 246px;
      font-family: Onest-Medium;
      & span {
        font-family: Onest-Bold;
      }
    }
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-bottom: 16px;
    & > div {
      & p {
        max-width: 100%;
        font-family: Onest-Medium;
        & span {
          font-family: Onest-Bold;
        }
      }
    }
  }
`;
