import styled from 'styled-components';

interface Props {
  selected?: boolean;
  displayType?: string;
  issue?: boolean;
}

export const NumberContainer = styled.div<Props>`
  background-color: ${(props) =>
    props.selected == true
      ? props.issue
        ? 'rgba(255, 216, 170, 1)'
        : `${props.theme.palette.white.main}`
      : `transparent`};
  border-radius: 8px;
  color: ${(props) =>
    props.selected == true
      ? props.issue
        ? 'rgba(255, 141, 7, 1)'
        : `${props.theme.palette.black.main}`
      : `#4D4D4D`};
  border: ${(props) => (props.selected ? 'none' : '2px solid #4D4D4D')};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props: Props) =>
    props.displayType === 'header' ? '0 0 -2px 10px' : ''};
  font-size: 16px;
  position: relative;
  font-family: Onest-Bold;
  & div {
    font-family: Onest-Bold;
  }
`;
