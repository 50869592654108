import styled from 'styled-components';
import { COLORS } from '../../constants/colors';

export const EnableMembershipCardWrapper = styled.div<{ status: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 2px solid ${COLORS.drifflePlus};
  border-radius: 8px;
  background: ${(props) =>
    props.status ? COLORS.drifflePlus + '22' : 'transparent'};
  overflow: hidden;
  padding: 16px 0;
`;

export const EnableMembershipCardTitle = styled.div`
  padding: 0 16px;
  h3 {
    margin: 0;
    padding: 0;
    font: 1rem/1.25rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
  }
  span {
    font: 1rem/1.25rem Onest-Bold;
    color: ${COLORS.drifflePlusText};
  }
`;

export const IllustrationPlus = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 60px;
  height: 60px;
`;

export const EnableMembershipCardBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const EnableMembershipCardBodyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 0 0 16px;
`;

export const EnableMembershipCardListItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 242px;
  p {
    font: 0.875rem/1.125rem Onest-Medium;
    color: ${(props) => props.theme.palette.text.t1};
  }
`;

export const ToggleButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 60px;
  margin-right: -8px;
`;
