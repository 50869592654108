import DOMPurify from 'isomorphic-dompurify';
import { memo } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import { TermsAndConditionsContainer } from '../CheckoutSummary/CheckoutSummaryStyle';

function CartTnc() {
  const { messages } = useLocale();
  const {
    by_proceeding_through_checkout_msg,
    terms_of_service_msg,
    refund_policy_msg,
    privacy_policy_msg,
  } = messages || {};

  return (
    <TermsAndConditionsContainer
      type={'cart'}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          by_proceeding_through_checkout_msg
            ?.replace(
              '[--TERMS_AND_CONDITIONS--]',
              `<span>
            <a href="/terms-and-conditions">
              ${terms_of_service_msg}
            </a>
          </span>`
            )
            .replace(
              '[--PRIVACY_POLICY--]',
              `<span>
            <a href="/privacy-policy">
              ${privacy_policy_msg}
            </a>
          </span>`
            )
            .replace(
              '[--REFUND_POLICY--]',
              `<span>
              <a href="/refund-policy">
                ${refund_policy_msg}
              </a>
            </span>`
            ) ?? ''
        ),
      }}
    />
  );
}

export default memo(CartTnc);
