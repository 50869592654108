import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const CartListContainer = styled.div`
  flex: 2;
  // padding: 16px;
  // background: #f8f8f8;
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 0px;
    margin-right: 0;
    margin-bottom: 16px;
  }
  @media (max-width: ${WIDTH.mobileMid}) {
    // background: #ffffff;
  }
`;
