import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const BarContainer = styled.div`
  // padding: 13px;
  // width: 100%;
  width: 700px;
  display: flex;
  // margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  // margin-bottom: 32px;
  @media (max-width: 1609px) {
    width: 500px;
  }
  @media (max-width: ${WIDTH.laptopMax}) {
    left: 45%;
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    left: 40%;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    left: 0;
    transform: none;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    padding: 0;
    display: none;
  }
`;

export const LogoContainer = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
`;

export const StatusBarContainer = styled.div`
  // margin-left: 20px;
  width: 100%;
  // height: 51px;
  // padding: 12px 16px;
  border-radius: 6px;
  /* background-color: ${COLORS.primaryLight3}; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${WIDTH.tabletMid}) {
    margin: 0;
    border-radius: 0;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  selected: boolean;
}

export const StatusTextContainer = styled.div<{
  issue?: boolean;
  selected: boolean;
}>`
  font-size: 16px;
  font-family: Onest-Bold;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${(props) =>
    props.selected == true
      ? props.issue
        ? 'rgba(255, 216, 170, 1)'
        : `${props.theme.palette.white.main}`
      : `#909090`};
  margin-left: 12px;
  @media (max-width: ${WIDTH.tabletMid}) {
    display: ${(props: Props) => (props.selected == true ? 'block' : 'none')};
  }
`;

export const CheckIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;
