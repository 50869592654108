import { ReactElement } from 'react';
import { NumberContainer } from './NumberCircleStyle';

interface Props {
  number: ReactElement;
  selected?: boolean;
  displayType?: string;
  issue?: boolean;
}

const NumberCircle = (props: Props) => {
  return (
    <NumberContainer
      issue={props.issue}
      selected={props.selected}
      displayType={props.displayType}
    >
      {props.number}
    </NumberContainer>
  );
};

export default NumberCircle;
