import { COLORS } from '../../constants/colors';

import { useAppSelector } from '../../redux/hooks';
import { MaskIcon } from '../Icons/Icons';
// import { CartPromoMessages } from './messages';
import { memo } from 'react';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  ApplyCouponText,
  HaveCouponText,
  MobileCartPromoContainer,
} from './MobileCartPromoStyle';

const MobileCartPromo = () => {
  const { messages } = useLocale();
  const { have_coupon_msg, applied_msg, apply_msg } = messages || {};

  const { cart } = useAppSelector((state) => state);

  return (
    <MobileCartPromoContainer>
      <div>
        <MaskIcon
          url="/icons/promo-purple-icon.svg"
          height="13px"
          width="13px"
          margin="0 5px 0 0px"
          selected={true}
          color={COLORS.purple}
        />
        {cart.promo?.code ? (
          <HaveCouponText>
            {cart.promo.code} {applied_msg}
          </HaveCouponText>
        ) : (
          <HaveCouponText>{have_coupon_msg}</HaveCouponText>
        )}
      </div>
      {cart.promo?.code ? (
        <HaveCouponText>
          {/* {remove_coupon}  */}
          <MaskIcon
            url="/icons/delete.svg"
            selected={true}
            color={COLORS.purple}
            height="18px"
            width="14px"
          />
        </HaveCouponText>
      ) : (
        <ApplyCouponText>{apply_msg}</ApplyCouponText>
      )}
    </MobileCartPromoContainer>
  );
};

export default memo(MobileCartPromo);
